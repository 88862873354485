import { supabase } from './supabase';
import { useStore } from '../store/useStore';

export interface AuthResponse {
  session: any;
  profile: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    organizationName: string;
    organizationSize: string;
    planId: 'basic' | 'pro';
  };
}

interface SignUpMetadata {
  firstName: string;
  lastName: string;
  organizationName: string;
  organizationSize: string;
}

export async function signUp(email: string, password: string, metadata: SignUpMetadata) {
  if (!email || !password) {
    throw new Error('Email and password are required');
  }

  try {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          first_name: metadata.firstName,
          last_name: metadata.lastName,
          organization_name: metadata.organizationName,
          organization_size: metadata.organizationSize,
          plan_id: 'basic'
        }
      }
    });

    if (error) {
      throw new Error(error.message || 'Failed to create account');
    }

    if (!data?.user) {
      throw new Error('Failed to create user account');
    }

    const store = useStore.getState();
    store.addNotification({
      title: 'Account Created',
      message: 'Your account has been created successfully. Welcome to Algozi!',
      type: 'success'
    });

    return data;
  } catch (error) {
    console.error('Sign up error:', error);
    throw error instanceof Error ? error : new Error('Failed to create account');
  }
}

export async function signIn(email: string, password: string): Promise<AuthResponse> {
  if (!email || !password) {
    throw new Error('Email and password are required');
  }

  try {
    const { data: authData, error: signInError } = await supabase.auth.signInWithPassword({
      email,
      password,
      options: {
        persistSession: true
      }
    });

    if (signInError) {
      throw new Error(signInError.message || 'Invalid email or password');
    }

    if (!authData?.session?.user) {
      throw new Error('No user data found');
    }

    const { data: profile, error: profileError } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', authData.session.user.id)
      .single();

    if (profileError) {
      throw new Error('Failed to fetch user profile');
    }

    if (!profile) {
      throw new Error('User profile not found');
    }

    const store = useStore.getState();
    store.addNotification({
      title: 'Login Successful',
      message: `Welcome back, ${profile.first_name}! You logged in at ${new Date().toLocaleString()}`,
      type: 'success'
    });

    return { 
      session: authData.session, 
      profile: {
        id: profile.id,
        email: profile.email,
        firstName: profile.first_name,
        lastName: profile.last_name,
        organizationName: profile.organization_name,
        organizationSize: profile.organization_size,
        planId: profile.plan_id as 'basic' | 'pro'
      }
    };
  } catch (error) {
    console.error('Login error:', error);
    throw error instanceof Error ? error : new Error('Invalid email or password');
  }
}

export async function initializeAuth() {
  try {
    const { data: { session }, error } = await supabase.auth.getSession();
    
    if (error) throw error;
    
    if (session?.user) {
      const { data: profile } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', session.user.id)
        .single();

      if (profile) {
        const store = useStore.getState();
        store.setUserData({
          id: profile.id,
          email: profile.email,
          firstName: profile.first_name,
          lastName: profile.last_name,
          organizationName: profile.organization_name,
          organizationSize: profile.organization_size,
          planId: profile.plan_id
        });
      }
    }

    return session;
  } catch (error) {
    console.error('Auth initialization error:', error);
    return null;
  }
}