import React from 'react';
import { Loader2 } from 'lucide-react';

export default function AuthLoader() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900">
      <div className="text-center">
        <Loader2 className="h-8 w-8 text-indigo-600 dark:text-indigo-400 animate-spin mx-auto" />
        <p className="mt-4 text-gray-600 dark:text-gray-400">Loading your account...</p>
      </div>
    </div>
  );
}