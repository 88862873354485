import { useState, useEffect } from 'react';
import { supabase } from '../../supabase';
import { useStore } from '../../../store/useStore';

export function useAuthState() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { setUserData } = useStore();

  useEffect(() => {
    let mounted = true;

    async function checkAuth() {
      try {
        // Get the current session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (!mounted) return;
        
        if (sessionError) throw sessionError;

        if (session?.user) {
          // Fetch user profile
          const { data: profile, error: profileError } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', session.user.id)
            .single();

          if (!mounted) return;
          
          if (profileError) throw profileError;

          if (profile) {
            setUserData({
              id: profile.id,
              email: profile.email,
              firstName: profile.first_name,
              lastName: profile.last_name,
              organizationName: profile.organization_name,
              organizationSize: profile.organization_size,
              planId: profile.plan_id
            });
          }
        }

        setLoading(false);
      } catch (err) {
        if (!mounted) return;
        console.error('Auth check error:', err);
        setError(err instanceof Error ? err : new Error('Authentication failed'));
        setLoading(false);
      }
    }

    // Initial check
    checkAuth();

    // Set up auth state change listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_IN' && session?.user) {
        checkAuth();
      } else if (event === 'SIGNED_OUT') {
        setUserData(null);
      }
    });

    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, [setUserData]);

  return { loading, error };
}