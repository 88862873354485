import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import { useStore } from '../store/useStore';
import { useAuthState } from '../lib/auth/hooks/useAuthState';
import AuthLoader from './auth/AuthLoader';

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const { setUserData } = useStore();
  const { loading, error } = useAuthState();

  useEffect(() => {
    if (error) {
      console.error('Auth error:', error);
      navigate('/login');
    }
  }, [error, navigate]);

  // Handle auth state changes
  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event) => {
      if (event === 'SIGNED_OUT') {
        setUserData(null);
        navigate('/login');
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [navigate, setUserData]);

  if (loading) {
    return <AuthLoader />;
  }

  return <>{children}</>;
}