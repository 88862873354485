import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CheckCircle, ArrowRight } from 'lucide-react';
import { useStore } from '../store/useStore';
import { getUserProfile } from '../lib/auth/session';

export default function PaymentSuccessPage() {
  const navigate = useNavigate();
  const { userData, setUserData } = useStore();

  useEffect(() => {
    // Refresh user data to get updated plan status
    const refreshUserData = async () => {
      if (userData?.id) {
        const profile = await getUserProfile(userData.id);
        if (profile) {
          setUserData({
            ...userData,
            planId: profile.plan_id
          });
        }
      }
    };

    refreshUserData();

    // Auto-redirect after 5 seconds
    const timer = setTimeout(() => {
      navigate('/dashboard');
    }, 5000);

    return () => clearTimeout(timer);
  }, [userData, setUserData, navigate]);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center p-4">
      <div className="max-w-md w-full text-center">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
          <div className="w-16 h-16 bg-green-100 dark:bg-green-900 rounded-full flex items-center justify-center mx-auto mb-4">
            <CheckCircle className="h-8 w-8 text-green-600 dark:text-green-400" />
          </div>
          
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Payment Successful!
          </h1>
          
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            Thank you for upgrading to Pro! Your account has been upgraded with unlimited data sources and premium features.
          </p>

          <Link
            to="/dashboard"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
          >
            Go to Dashboard
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>

          <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
            You will be automatically redirected in a few seconds...
          </p>
        </div>
      </div>
    </div>
  );
}