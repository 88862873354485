import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../store/useStore';
import { supabase } from '../supabase';

export const useHandlePaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateUserPlan, addNotification } = useStore();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const upgrade = searchParams.get('upgrade');
    const plan = searchParams.get('plan');

    if (upgrade === 'success' && plan === 'pro') {
      const handleSuccess = async () => {
        try {
          // Get current user
          const { data: { user } } = await supabase.auth.getUser();
          
          if (!user) {
            throw new Error('No authenticated user found');
          }

          // Get updated profile to confirm upgrade
          const { data: profile, error } = await supabase
            .from('profiles')
            .select('plan_id, payment_status')
            .eq('id', user.id)
            .single();

          if (error) throw error;

          if (profile.plan_id === 'pro' && profile.payment_status === 'completed') {
            await updateUserPlan('pro');
            
            addNotification({
              title: 'Welcome to Pro!',
              message: 'Your account has been upgraded successfully. You now have access to unlimited data sources.',
              type: 'success'
            });

            // Clear URL parameters and redirect to dashboard
            navigate('/dashboard', { replace: true });
          }
        } catch (error) {
          console.error('Error handling payment success:', error);
          addNotification({
            title: 'Upgrade Error',
            message: 'There was an issue verifying your upgrade. Please contact support.',
            type: 'error'
          });
        }
      };

      handleSuccess();
    }
  }, [location, navigate, updateUserPlan, addNotification]);
};