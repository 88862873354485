import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeft, AlertCircle } from 'lucide-react';
import { useStore } from '../store/useStore';
import { signUp } from '../lib/auth';

export default function SignUpPage() {
  const navigate = useNavigate();
  const { setUserData } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    organizationName: '',
    organizationSize: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      if (!formData.firstName || !formData.lastName || !formData.email || 
          !formData.password || !formData.organizationName || !formData.organizationSize) {
        throw new Error('All fields are required');
      }

      if (formData.password !== formData.confirmPassword) {
        throw new Error("Passwords don't match");
      }

      if (formData.password.length < 8) {
        throw new Error('Password must be at least 8 characters');
      }

      const { user } = await signUp(formData.email, formData.password, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        organizationName: formData.organizationName,
        organizationSize: formData.organizationSize
      });

      if (!user) {
        throw new Error('Failed to create account');
      }

      setUserData({
        id: user.id,
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        organizationName: formData.organizationName,
        organizationSize: formData.organizationSize,
        planId: 'basic'
      });

      navigate('/dashboard');
    } catch (err) {
      console.error('Registration error:', err);
      setError(err instanceof Error ? err.message : 'Failed to create account');
    } finally {
      setIsLoading(false);
    }
  };

  // Rest of the component remains the same...
}