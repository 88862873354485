import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useStore } from '../../store/useStore';
import { useSession } from '@supabase/auth-helpers-react';
import AuthLoader from './AuthLoader';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const location = useLocation();
  const session = useSession();
  const { userData, isAuthenticated } = useStore();
  const isLoading = !session && !userData;

  if (isLoading) {
    return <AuthLoader />;
  }

  if (!isAuthenticated && !session) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}